import { useEffect, useState } from 'react';
import { HiOutlineChevronDown } from 'react-icons/hi';
import { AiOutlineClear } from 'react-icons/ai';

export default function SelectAutocomplete({
  data,
  defaultValue,
  setParams,
  placeholder,
  label,
  clear = false,
  disabled = true,
}) {
  const [open, setOpen] = useState(false);
  const [copyData, setCopyData] = useState(null);
  const [inputText, setInputText] = useState('');
  const [selected, setSelected] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
    setCopyData(data);
  };

  const handleSelected = (name, id) => {
    setParams(id);
    setInputText(name);
    setOpen(!open);
    setSelected(true);
  };

  const handleClear = () => {
    setInputText('');
    setSelected(false);
    setParams('');
  };

  const filtereds = (value) => {
    const listData = [...data] || []
    const toFilter = listData.filter((item) =>
      item.name.toUpperCase().includes(value.toUpperCase()),
    );
    setCopyData(toFilter);
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setInputText(value);
    setOpen(true);
    filtereds(value);
  };

  useEffect(() => {
    setCopyData(data);
  }, [data]);

  useEffect(() => {
    if (typeof defaultValue !== 'undefined') {
      setInputText(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (clear) {
      handleClear();
    }
  }, [clear]);

  return (
    <main className="relative min-w-[250px] w-1/2 text-sm col-span-1 md:w-5/6">
      <span
        className={`font-semibold pl-1 ${
          disabled ? 'text-gray-300' : 'text-[#273447]'
        }`}
      >
        {label ? label : ''}
      </span>

      <section
        className="w-full h-8 flex justify-between pr-2 pl-[12px] py-[1px] mb-[3px] mt-1 rounded-[10px] shadow-md"
        style={{
          border: `${disabled ? '1px solid #d1d5db' : '1px solid #a3a3a3'}`,
        }}
      >
        <input
          className="bg-transparent focus:outline-none w-full pt-[2px] pr-2 disabled:text-gray-300"
          type="text"
          value={inputText}
          placeholder={placeholder ? placeholder : ''}
          disabled={disabled}
          onChange={handleChange}
        />

        <button
          className={`${inputText || selected ? 'block' : 'hidden'} ${
            disabled ? 'text-gray-300' : 'text-[#50B8E4] hover:text-[#273447]'
          }`}
          onClick={handleClear}
          disabled={disabled}
        >
          <AiOutlineClear size={20} />
        </button>

        <button
          className={`${!inputText && !selected ? 'block' : 'hidden'} ${
            disabled ? 'text-gray-300' : 'text-[#50B8E4] hover:text-[#273447]'
          }`}
          onClick={handleOpen}
          disabled={disabled}
        >
          <HiOutlineChevronDown size={20} />
        </button>
      </section>

      {open && (
        <div
          className="absolute bg-white w-full rounded-[10px] max-h-[120px] overflow-hidden shadow-md z-40"
          style={{ border: '1px solid #50B8E4' }}
        >
          <ul className="w-full max-h-[120px] overflow-y-auto overflow-x-hidden py-2">
            {copyData &&
              copyData.map((item) => (
                'hidden' in item && item.hidden ? null : (
                  <li key={item.id} className="whitespace-nowrap w-full">
                    <button
                      className="hover:bg-gray-200 py-[3px] px-2 w-full text-left"
                      onClick={() => handleSelected(item.name, item.id)}
                    >
                      {item.name}
                    </button>
                  </li>
                )
              ))}
          </ul>
        </div>
      )}
    </main>
  );
}
