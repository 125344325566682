import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { LANGUAGES_FILES } from '../../../../constants/enums';

import {
  setAuthorization,
  setInformationUser,
} from '../../../../redux/reducer/authorization/actions/authorizationsActions';

import { logOut } from '../../../../services/api/v1/user_requests/userRequests';

import ButtonGeneral from '../../../../components/ButtonGeneral';
import ButtonCancel from '../../../../components/ButtonCancel';

import DecryptedToken from '../../../../components/security_components/decrypted_token/decrypted_token';
import { useTranslation } from 'react-i18next';

export default function LogOut({ handleClose }) {
  const authorization = DecryptedToken();
  const [t] = useTranslation(LANGUAGES_FILES.NAVBAR);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = async () => {
    await logOut(authorization);
    dispatch(setAuthorization(''));
    dispatch(setInformationUser([]));
    navigate('/login');
  };

  return (
    <section className="bg-white h-[50%] rounded-b-[4px] overflow-hidden">
      <div className="h-full overflow-auto">
        <h2 className="text-[#273447] text-center text-[18px] font-semibold pt-4 pb-2">
          {t('navbar.views.profile.log-out.layout.title')}
        </h2>

        <div className="flex flex-col h-3/4 gap-14 pt-8 pb-3">
          <span className="text-center">
            {t('navbar.views.profile.log-out.layout.confirmation-text')}
          </span>

          <section className="flex justify-evenly">
            <ButtonGeneral
              value={t('navbar.views.profile.log-out.layout.buttons.accept')}
              onClick={handleLogout}
            />
            <ButtonCancel
              value={t('navbar.views.profile.log-out.layout.buttons.cancel')}
              onClick={handleClose}
            />
          </section>
        </div>
      </div>
    </section>
  );
}
